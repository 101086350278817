import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/LayoutComponents/Layout'
import Seo from '../components/Seo'
import Hero from '../components/Hero'
import CardContainer from '../components/GridComponents/CardContainer'
import Card from '../components/GridComponents/Card'
import CardContainer2Col from '../components/GridComponents/CardContainer2Col'
import CardContent from '../components/GridComponents/CardContent2'
import About from '../components/About'

const IndexPage = ({ data }) => {
  const yaml = data.textYaml
  return (
    <>
      <Hero
        colorheading={yaml.yellowTitle}
        image={data.index.childImageSharp.gatsbyImageData}
        heading={yaml.whiteTitle}
        subheading={yaml.heroSubheading}
      />
      <About
        image={data.lightbulb.childImageSharp.gatsbyImageData}
        mimage={data.lightbulbSm.childImageSharp.gatsbyImageData}
        text={<p>As of July 2022, Khoza has effectively closed. We've appreciated the many years serving our clients and wish them the best. You can email <a className="text-my-yellow hover:text-white" href="mailto:support@khoza.com">support@khoza.com</a> for questions and inquiries.</p>}
      />
      {/* <CardContainer heading={yaml.cardContainerHeading}> */}
      {/*   {yaml.cardContainer.map((cardContainer, index) => ( */}
      {/*     <Card */}
      {/*       key={index} */}
      {/*       heading={cardContainer.heading} */}
      {/*       to={cardContainer.to} */}
      {/*       text={cardContainer.text} */}
      {/*       image={cardContainer.image} */}
      {/*     /> */}
      {/*   ))} */}
      {/* </CardContainer> */}
      {/* <CardContainer2Col */}
      {/*   heading={yaml.iconRowsHeading} */}
      {/*   subheading={yaml.iconRowsSubheading} */}
      {/* > */}
      {/*   {yaml.iconRows.map((iconRows, index) => ( */}
      {/*     <CardContent */}
      {/*       key={index} */}
      {/*       heading={iconRows.heading} */}
      {/*       logo={iconRows.logo} */}
      {/*       text={iconRows.text} */}
      {/*     /> */}
      {/*   ))} */}
      {/* </CardContainer2Col> */}
      <Layout>
        <Seo title={yaml.pageTitle} />
      </Layout>
    </>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query MyQuery {
    index: file(relativePath: { eq: "puzzlepieces-min.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 900, layout: CONSTRAINED)
      }
    }
    lightbulb: file(relativePath: { eq: "paperbulb2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, width: 350, layout: FIXED)
      }
    }
    lightbulbSm: file(relativePath: { eq: "paperbulb2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: FIXED)
      }
    }
    textYaml(yamlFile: { relativePath: { eq: "index.yml" } }) {
      pageTitle
      whiteTitle
      yellowTitle
      heroImage
      heroSubheading
      heroLogo
      aboutImage
      aboutText
      cardContainerHeading
      iconRowsHeading
      iconRowsSubheading
      cardContainer {
        heading
        image
        text
        to
      }
      iconRows {
        heading
        logo
        text
      }
    }
  }
`

export default IndexPage
